<template>
  <b-modal id="unenroll-modal" centered size="md" hide-footer hide-header>
    <div class="cd-enroll__div cd-main">
      <b-row class="h-100 d-flex align-items-center">
        <b-col md="12" class="h-100">
          <h3>
            <b-icon
              icon="question-circle-fill"
              style="color: #0043ac"
              class="mr-3"
            ></b-icon
            >{{ $t("course.unenroll.alert.title") }}
          </h3>
          <p class="cd-sec__des">
            {{ $t("course.unenroll.alert.message") }}
          </p>
          <div class="cd-sec__additional">
            <b-row>
              <b-col class="cd-sec__footer">
                <b-button class="ob-btn ob-btn-primary mr-3" @click="unenroll"
                  >{{ $t("course.unenroll.title") }}
                </b-button>
                <b-button
                  class="ob-btn btn-secondary"
                  @click="$bvModal.hide('unenroll-modal')"
                  >{{ $t("general.cancel") }}</b-button
                >
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  props: {
    courseId: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters(["allConfig"])
  },
  methods: {
    unenroll() {
      axios
        .post(
          `${this.allConfig.baseURL}/enrollments/v2/courses/${this.courseId}/unenroll/`,
          {},
          {
            headers: {
              Authorization: `Bearer ${this.$keycloak.token}`
            }
          }
        )
        .then(() => {
          this.$bvModal.hide("unenroll-modal");
          this.$emit("onUnenroll");
        })
        .catch(err => {
          this.$store.commit("SET_ERRORS", err.response.data);
        });
    }
  }
};
</script>
<style lang="scss">
#unenroll-modal {
  .modal-content {
    border-radius: 16px;
    .modal-body {
      padding: 16px 24px;
      h3 {
        @include subtitle-large;
      }
      p {
        @include body-regular;
        margin: 20px 0 30px;
      }
    }
  }
}
</style>
